.team-profile__description {
  margin-bottom: 10px;
  color: #42526e;
}
.btn-team-profile {
  height: 30px;
  padding-left: 9px;
  padding-right: 9px;
  font-size: 11px;
  font-weight: 500;
  color: #0052cc;
}

.btn-team-profile .anticon {
  color: #8993a4;
  font-size: 12px;
  margin-right: 1px;
  transition: color 0.3s linear;
}
.btn-team-profile:hover,
.btn-team-profile:active,
.btn-team-profile:focus {
  color: #3572b0;
  border: 1px solid #dfe1e6;
  background-color: #f1f5f7;
}

.btn-team-profile:hover .anticon {
  color: #3572b0;
}
