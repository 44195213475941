/* Profile list */
/* Table flexible column, it's for profile item on hover */
.profile-list.profile-list--table-column {
  width: 0;
  min-width: 100%;
}
.profile-list__rest {
  font-size: 13px;
  font-weight: 500;
  color: #0052cc;
}
.profile-list__rest:hover {
  color: #3572b0;
}

/* Profile item */
.profile-item {
  border: 4px solid #f1f5f7;
  background-color: #0052cc;
  width: 40px;
  height: 40px;
  line-height: 32px;
  font-size: 9px;
  color: #fff;
  font-weight: 600;
  text-align: center;
  transition: all 0.2s linear;
}
.profile-item:hover {
  border-color: #f1f5f7;
  background-color: #3572b0;
  cursor: pointer;
}

/* Profile item tooltip */
.profile-item__tooltip {
  padding: 0;
}
.profile-item__tooltip .ant-tooltip-content .ant-tooltip-arrow {
  top: -15px;
  right: 11px;
  width: 18px;
  height: 18px;
}
.profile-item__tooltip .ant-tooltip-content .ant-tooltip-arrow:before {
  background-color: #fff;
  width: 9px;
  height: 9px;
  border-top: 1px solid #dfe1e6;
  border-left: 1px solid #dfe1e6;
}
.profile-item__tooltip .ant-tooltip-inner {
  padding: 10px 15px;
  font-size: 11px;
  font-weight: 500;
  color: #333;
  border-radius: 4px;
  border: 1px solid #dfe1e6;
  background-color: #fff;
  box-shadow: 0 12px 27px 3px rgba(25, 39, 52, 0.17);
}
.profile-item__tooltip .anticon {
  color: #0052cc;
  margin-right: 6px;
}

@media (min-width: 992px) {
  .profile-list--right .profile-item:not(:last-of-type) {
    margin-right: -14px;
  }
  .profile-list--right:hover .profile-item {
    margin-right: 0;
  }

  .profile-list--left .profile-item:not(:first-of-type) {
    margin-left: -14px;
  }
  .profile-list--left:hover .profile-item {
    margin-left: 0;
  }
}
