body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;
}

/* Default Button */
.btn:hover {
  box-shadow: 0 12px 27px 1px rgba(111, 116, 126, 0.22);
}

.btn-default {
  border-radius: 4px;
  background-color: #ffffff;
}

.btn-default:hover {
  color: #3572b0;
  background-color: #f1f5f7;
  border-color: #dfe1e6;
  box-shadow: 0 12px 27px 1px rgba(111, 116, 126, 0.22);
}

.ant-btn-primary {
  background-color: #0052cc;
  border-color: #0052cc;
}

.ant-btn-primary:hover {
  color: #fff;
  background-color: #3572b0;
  border-color: #3572b0;
}

/* Search input */
.search-input {
  height: 34px;
  width: 100%;
  margin-bottom: 15px;
}

.search-input .ant-input-search-icon {
  color: #42526e;
  font-size: 17px;
  transition: color 0.2s linear;
  transform: rotateY(180deg);
}

.search-input .ant-input-search-icon:hover {
  color: #3572b0;
}

.search-input .ant-input {
  color: #333;
  font-size: 13px;
  font-weight: 500;
  border: 1px solid #cbd6e2;
  background-color: #f5f8fa;
}

.search-input .ant-input::placeholder {
  color: #42526e;
}

.search-input .ant-input:active,
.search-input .ant-input:focus {
  background-color: #fff;
}

/* Alert */
.alert {
  background-color: transparent;
  border: none;
  padding: 8px 15px 8px 21px;
}

.alert .ant-alert-icon {
  left: 0;
}

.alert .ant-alert-message {
  color: #6f747e;
  font-size: 13px;
  font-weight: 500;
}

@media (min-width: 576px) {
  .search-input {
    width: 220px;
  }
}

@media (min-width: 992px) {
  .search-input {
    margin-bottom: 0;
  }
}


/* ===== start EBS Table Style */

.table {
  border: 1px solid #dfe1e6;
  border-radius: 4px;
}

.table .ant-table-body {
  overflow-x: auto;
}

.table .ant-table-thead > tr > th {
  font-size: 11px;
  line-height: 1.2;
  font-weight: bold;
  text-transform: uppercase;
  color: #1f325f;
  background-color: #fff;
  border-bottom: 1px solid #cbd6e2;
}

.table .ant-table-thead > tr > th.ant-table-selection-column,
.table .ant-table-tbody > tr > td.ant-table-selection-column {
  padding-right: 6px;
  padding-left: 20px;
}

.table .ant-table-thead > tr > th.ant-table-selection-column + th,
.table .ant-table-tbody > tr > td.ant-table-selection-column + td {
  padding-left: 7px;
}

.table .ant-table-thead > tr > th {
  padding: 20px 13px;
}

.table .ant-table-tbody > tr > td {
  color: #262626;
  font-size: 12px;
  font-weight: 500;
  padding: 13px;
  border-bottom-color: #dfe4ec;
}

.table .ant-table-tbody > tr:hover {
  background-color: #f1f5f7;
}

.table .ant-table-tbody > tr:nth-child(odd) {
  background-color: #f7fafc;
}

.table .table-cell-actions a {
  float: right;
  color: #1f325f;
}

.table .table-cell-actions .action-link {
  font-weight: 600;
  color: #1f325f;
  cursor: pointer;
}

/* Table row checkbox */
.table .ant-checkbox-inner {
  background-color: #dfe1e6;
  border-color: #bcc1d3;
  border-radius: 3px;
}

.table .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #0052cc;
  border-color: #0052cc;
  box-shadow: 0 3px 4px 0 rgba(111, 116, 126, 0.12);
  border-radius: 3px;
}

.table .ant-checkbox-checked::after {
  border-radius: 3px;
}

/* Table Header */
.table .ant-table-title {
  padding: 0;
  top: 0;
}

/* Table Footer */
.table .ant-table-footer {
  padding: 20px;
}

.table .table-footer {
  height: 32px;
}

/* Sorter icon */
.table
.ant-table-thead
> tr
> th
.ant-table-column-sorter
.ant-table-column-sorter-inner {
  height: 18px;
}

.table
.ant-table-thead
> tr
> th
.ant-table-column-sorter
.ant-table-column-sorter-inner-full {
  margin-top: -3px;
}

/* Table small */
.table-small .ant-table-thead > tr > th {
  background-color: #f7fafc;
}

.table-small .ant-table-thead > tr > th,
.table-small .ant-table-tbody > tr > td {
  padding: 12px 20px;
}

.table-small .ant-table-tbody > tr:hover {
  background-color: #fff;
}

.table-small .ant-table-tbody > tr:nth-child(odd) {
  background-color: #fff;
}

.table-small .ant-table-footer {
  padding: 14px 20px;
}

/* Table Pagination */
.table .ant-table {
  z-index: 1;
}

.table .ant-table-pagination {
  position: relative;
  z-index: 2;
  margin-top: -52px;
  margin-right: 20px;
}

.table-pagination .ant-pagination-prev,
.table-pagination .ant-pagination-next,
.table-pagination .ant-pagination-item,
.table-pagination .table-pagination__btn {
  height: 28px;
  line-height: 28px;
  color: #1f325f;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.table-pagination .ant-pagination-item:hover,
.table-pagination .table-pagination__btn:hover {
  color: #3572b0;
  border: 1px solid #dfe1e6;
  background-color: #f1f5f7;
  box-shadow: 0 12px 26.6px 1.4px rgba(111, 116, 126, 0.22);
}

.table-pagination .ant-pagination-disabled .table-pagination__btn,
.table-pagination .ant-pagination-disabled .table-pagination__btn:hover {
  color: #8993a4;
  border: 1px solid #dfe1e6;
  background-color: #f4f5f7;
  box-shadow: none;
}

/* ===== end EBS Table Style */