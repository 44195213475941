.app-header.page-header {
  margin-bottom: 30px;
}

.app-header__modal .ant-modal-content {
  box-shadow: 0 12px 27px 1px rgba(111, 116, 126, 0.22);
}

.app-header__modal .ant-modal-body {
  background-color: #f7fafc;
  padding: 20px 20px 0 20px;
  min-height: 256px;
  max-height: 492px;
  overflow-y: auto;
}

.app-header__modal .ant-spin-nested-loading {
  min-height: 206px;
}

.app-header__modal .ant-modal-header,
.app-header__modal .ant-modal-footer {
  padding: 20px;
}

.app-header__modal .ant-modal-header .ant-modal-title {
  font-size: 14px;
  line-height: 20px;
  color: #333;
  font-weight: 600;
}

.app-header__modal .ant-modal-footer button + button {
  margin-left: 15px;
}

.app-header__form-modal {
  margin-top: 100px;
}
