.team-form label {
  font-size: 14px;
  font-weight: 500;
  margin-right: 20px;
  min-width: 50px;
}
.team-form__row {
  display: flex;
  align-items: center;
}
.team-form__row:not(:last-child) {
  margin-bottom: 20px;
}
.team-form__input:focus {
  border-color: #b4d4fd;
  box-shadow: none;
}
.team-form__select {
  margin: 0;
}
.team-form__select .ant-select-selection {
  box-shadow: none;
}
.team-form__input,
.team-form__select .ant-select-search__field,
.team-form__select .ant-select-selection__placeholder {
  font-size: 13px;
  font-weight: 500;
}
