.user-form__headings h3 {
  font-size: 18px;
  color: #333333;
  font-weight: 500;
  margin: 0;
}
.user-form__headings p {
  font-size: 13px;
  color: #333333;
  line-height: 1.692;
  margin: 0;
}

.user-form__wrapper {
  border: 1px dashed #cbd6e2;
  border-radius: 12px;
  background-color: #f7fafc;
  padding: 20px 30px;
}
