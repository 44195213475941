.profile-wrapper {
  display: flex;
  padding: 16px;
  border: 1px dashed #b4d4fd;
  border-radius: 4px;
  background-color: #f7fafc;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 20px;
  flex-direction: column;
}
.profile-wrapper .ant-list-item {
  padding: 0;
}
.profile-wrapper .ant-list-item-meta-avatar {
  margin-right: 14px;
}
.profile-wrapper .ant-list-item-meta-title {
  font-size: 15px;
  color: #1f325f;
  font-weight: 600;
  line-height: 1.2;
}
.profile-wrapper .ant-list-item-meta-description {
  font-size: 12px;
  font-weight: 500;
}
.profile-wrapper .ant-spin {
  margin: 20px auto;
}

/* Profile actions buttons */
.profile-actions {
  display: flex;
  margin-top: 15px;
}
.profile-actions .ant-btn {
  height: 30px;
  padding: 0 10px;
}
.profile-actions .ant-btn:not(:last-child) {
  margin-right: 10px;
}
.profile-actions .btn-dark-background {
  background-color: #172b4d;
  border-color: #172b4d;
}
.profile-actions .btn-dark-background:hover {
  background-color: #42526e;
  border-color: #42526e;
  color: #eaf0f6;
}

@media (min-width: 576px) {
  .profile-wrapper {
    flex-direction: row;
  }
  .profile-actions {
    margin-top: 0;
  }
}
