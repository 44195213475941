.profile-image {
  position: relative;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0 3px 4px 0 rgba(111, 116, 126, 0.12);
  transition: box-shadow 0.3s linear;
}
.profile-image .ant-avatar {
  background-color: #3474d2;
}
.profile-image .ant-avatar-string {
  text-transform: uppercase;
  font-weight: 500;
}
.profile-image:hover {
  box-shadow: 0 12px 26.6px 1.4px rgba(111, 116, 126, 0.22);
}
.profile-image:hover .profile-image__actions {
  opacity: 1;
  visibility: visible;
}
.profile-image__actions {
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s linear;
}
.profile-image__action-item {
  width: 24px;
  height: 24px;
  color: #fff;
  background-color: rgba(38, 38, 38, 0.5);
  margin-bottom: 3px;
  transition: all 0.3s linear;
  display: flex;
}
.profile-image__action-item svg {
  margin: auto;
}
.profile-image__action-item:hover {
  color: #3572b0;
  background-color: #fff;
  cursor: pointer;
}
