/* User role change */
.user-role {
  display: flex;
  justify-content: center;
  align-items: center;
}
.user-role label {
  width: 120px;
}
.user-role .ant-select {
  width: 100%;
}
