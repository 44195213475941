.application__large.application__item {
  border: 1px dashed #cbd6e2;
  padding: 35px 15px;
  height: calc(100% - 30px);
}

.application__large .application__item__title {
  font-size: 16px;
}

.application__large .application__item__description {
  font-size: 13px;
}

/* Creatable Item */
.application__item--creatable .application__item__avatar {
  margin: 0 auto 20px;
  transition: all 0.3s linear;
}
.application__item.application__item--creatable:hover {
  cursor: pointer;
  border: 1px dashed #0052cc;
  box-shadow: 0 15px 29px 1px rgba(31, 50, 95, 0.12),
    0 2px 20px 0 rgba(31, 50, 95, 0.05);
}
.application__item.application__item--creatable:hover .application__item__title,
.application__item.application__item--creatable:hover
  .application__item__description {
  color: #333;
}
.application__item--creatable:hover .application__item__avatar {
  color: #3572b0;
  background-color: #deebfe;
  cursor: pointer;
}

/* Controls */
.application__item__controls {
  position: absolute;
  top: 15px;
  left: 15px;
  display: inline-flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 20px;
  height: 20px;
}

.application__item__controls__icon {
  text-align: center;
  color: #1f325f;
  background-color: #f7fafc;
  font-size: 12px;
  font-weight: bold;
  padding: 4px;
  border-radius: 10px;
  display: flex;
  justify-content: flex-end;
  transition: background-color 0.3s linear;

  &.application__item__control--remove {
    justify-content: flex-start;
  }
}
.application__item__controls__icon:hover {
  cursor: pointer;
  box-shadow: 0 3px 4px 0 rgba(111, 116, 126, 0.4);
}
.application__item__controls__icon:only-child {
  margin-left: auto;
  margin-right: 0;
}
.application__item__controls__icon.application__item__control--remove {
  margin-left: 0;
  margin-right: auto;
}

.ant-modal-body .application__item__controls {
  width: calc(100% - 30px);
  height: calc(100% - 30px);

  &__icon {
    height: 100%;
    width: 100%;
    background-color: transparent;
    box-shadow: none;
  }

  &:hover {
    margin: -15px;
    padding: 15px;
    height: 100%;
    width: 100%;
  }

  &:hover .application__item__controls__icon {
    color: #fff;
  }
}

@media (min-width: 768px) {
  .application__large.application__item {
    margin-bottom: 24px;
  }
}

@media (min-width: 992px) {
  .application__large.application__item {
    margin-bottom: 30px;
  }
}

@media (min-width: 1200px) {
  .application__large.application__item {
    margin-bottom: 36px;
  }
}
