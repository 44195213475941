/* Table Footer */
.table-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.table-footer__records {
  font-size: 14px;
  font-weight: 500;
  color: #333333;
  line-height: 1.2;
}
.table-footer__nav {
  margin-left: auto;
  margin-right: 0;
}
