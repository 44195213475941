/* Permissions Application Item */
.permission-item {
  border: 1px dashed #cbd6e2;
  border-radius: 7px;
  background-color: #f7fafc;
  padding: 15px;
  margin-bottom: 7px;
  transition: all 0.3s linear;
}

.permission-item:hover {
  border-color: #b4d4fd;
  background-color: #ffffff;
}

.permission-item__label {
  font-size: 13px;
  color: #333333;
  margin-right: 15px;
}

/* Application */
.permission-item__app[class*="application__item--"]:hover
  .application__item__icon {
  color: #fff;
}
.permission-item__app[class*="application__item--"]:hover {
  border: none;
  background: none;
  box-shadow: none;
}
.permission-item__app .application__item__icon {
  margin: 0 20px 0 0;
}
.permission-item__app-title {
  font-size: 16px;
  font-weight: 600;
  color: #333333;
}

/* Status */
.permission-item__status-label {
  font-size: 13px;
  font-weight: 500;
  color: #333333;
  margin-right: 15px;
}
.permission-item__status-switch {
  height: 20px;
  line-height: 18px;
}
.permission-item__status-switch.ant-switch-checked {
  background-color: #0052cc;
}
.permission-item__status-switch.ant-switch-checked::after {
  left: 100% !important;
  margin-left: 2px;
  transform: translateX(-100%);
}

.permission-item__status-switch.ant-switch::after {
  height: 22px;
  width: 22px;
  border-radius: 22px;
  top: -2px;
  left: -2px;
  box-shadow: 0 1px 10px 0 rgba(111, 116, 126, 0.1),
    0 2px 10px 0 rgba(111, 116, 126, 0.16);
}

/* Role */
.permission-item__role-select {
  width: 240px;
  color: #42526e;
  font-size: 13px;
  font-weight: 500;
}
.permission-item__role-select .ant-select-selection__placeholder {
  color: #42526e;
}

.permission-item__role-select:not(.ant-select-disabled) .ant-select-selection {
  border: 1px solid #dfe1e6;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 3px 4px 0 rgba(111, 116, 126, 0.16);
}

.permission-item__role-select.ant-select-open .ant-select-arrow,
.permission-item__role-select.ant-select-open:hover .ant-select-arrow {
  color: #0052cc;
}
.permission-item__role-select.ant-select-open .ant-select-selection,
.permission-item__role-select.ant-select-open:hover .ant-select-selection {
  border: 1px solid #3572b0;
}

.permission-item__role-select:hover .ant-select-selection,
.permission-item__role-select:focus .ant-select-selection,
.permission-item__role-select:active .ant-select-selection {
  color: #333333;
  border: 1px solid #b4d4fd;
}
.permission-item__role-select:hover .ant-select-arrow,
.permission-item__role-select:focus .ant-select-arrow,
.permission-item__role-select:active .ant-select-arrow {
  color: #1f325f;
}
.permission-item__role-select.ant-select-disabled:hover .ant-select-selection {
  border: 1px solid #d9d9d9;
}

.permission-item__role-select .ant-select-arrow {
  transition: color 0.3s linear;
}

/* Role select dropdown */
.permission-item__role-dropdown {
  border: 1px solid #dfe4ec;
  box-shadow: 0 3px 4px 0 rgba(111, 116, 126, 0.16);
}
.permission-item__role-dropdown .permission-item__role-option {
  padding: 5px 10px;
  color: #1f325f;
  font-size: 12px;
  font-weight: 500;
}
.permission-item__role-dropdown .permission-item__role-option:not(:last-child) {
  border-bottom: 1px solid #dfe4ec;
}
.permission-item__role-dropdown .permission-item__role-option:hover,
.permission-item__role-dropdown
  .permission-item__role-option.ant-select-dropdown-menu-item-selected {
  color: #3572b0;
  background-color: #f1f5f7;
}

/* Utils */
.permission-item .ant-col {
  display: flex;
  align-items: center;
  padding: 0 5px;
}
.permission-item__status,
.permission-item__app {
  margin-bottom: 15px;
}

.permission-item__role,
.permission-item__status,
.permission-item__app {
  justify-content: space-between;
}

@media (min-width: 576px) {
  .permission-item__status {
    margin-bottom: 0;
  }
  .permission-item__role {
    justify-content: flex-end;
  }
  .permission-item__status,
  .permission-item__app {
    justify-content: flex-start;
  }
}

@media (min-width: 768px) {
  .permission-item__status,
  .permission-item__app {
    margin-bottom: 0;
  }
}

@media (min-width: 992px) {
  .permission-item {
    padding: 15px 30px;
  }
}
