.user-profile__description {
  margin-bottom: 4px;
  color: #0052cc;
}
.user-profile__tag {
  font-size: 11px;
  font-weight: 600;
  color: #fff;
  line-height: 18px;
  height: 20px;
  border-radius: 10px;
  margin-right: 4px;
  padding: 0 5px;
  background-color: #0052cc;
  border-color: #0052cc;
}
