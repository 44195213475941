.page-header {
  margin-bottom: 16px;
}
.page-header__title.ant-typography {
  font-size: 16px;
  font-weight: 600;
  margin-right: 10px;
  margin-bottom: 15px;
}
.page-header__buttons {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  flex-wrap: wrap;
}

.sync-ad-button {
  margin-right: 15px;
}

.page-header__buttons .ant-btn {
  font-size: 13px;
  line-height: 1.2;
  height: 34px;
}
.page-header__buttons .ant-radio-group,
.page-header__buttons .ant-btn:not(:last-child) {
  margin-right: 15px;
}
.page-header__buttons .ant-btn:first-child {
  border-color: #dfe1e6;
}
.page-header__buttons .ant-btn-primary:hover {
  background-color: #3572b0;
  border-color: #3572b0;
  box-shadow: 0 12px 27px 1px rgba(111, 116, 126, 0.22);
}

@media (min-width: 576px) {
  .page-header__search-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .page-header__buttons {
    align-items: center;
  }
}

@media (min-width: 768px) {
  .page-header__buttons,
  .page-header__search-box {
    justify-content: flex-start;
  }
}

@media (min-width: 992px) {
  .page-header__title.ant-typography {
    margin-bottom: 0;
  }
  .page-header__buttons {
    justify-content: flex-end;
  }
}
