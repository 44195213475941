.ant-layout-content {
  background: #fff;
  margin: 0;
  padding: 31px;
  max-height: calc(100vh - 58px);
}

.app-fallback {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.app-fallback .ant-spin-text {
  margin-top: 15px;
}

@media (min-width: 1200px) {
  .ant-layout-content .content-wrapper {
    max-width: 85vw;
    margin: 0 auto;
  }
}
