.auth {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background: #061b4d url("../../assets/images/auth-background.png") no-repeat
    center;
  background-size: cover;
}

.auth__header {
  margin-top: 40px;
  width: 90%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.auth .header__logo {
  display: flex;
  align-items: center;
}

.auth .header__logo .title {
  margin-left: 15px;
  font-size: 20px;
  color: #333333;
  font-weight: bold;
  line-height: 1.2;
}

.auth .header__questions {
  font-size: 14px;
  color: #b4d4fd;
  margin-right: 0;
  margin-left: auto;
}

.auth .header__questions a {
  color: #deebfe;
  font-weight: 500;
}

.auth .header__questions a:hover {
  color: white;
}

.auth__container {
  margin: 20px 0px;
  position: relative;
  min-width: 50%;
  max-width: 90%;
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  background-color: rgb(255, 255, 255);
  border-radius: 15px;
  box-shadow: 0px 12px 26.6px 1.4px rgba(0, 20, 56, 0.664);
  transition: cubic-bezier(0.785, 0.135, 0.15, 0.86) 1s;
}

.auth .container__about {
  background-color: rgb(0, 82, 204);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.auth .container__about .about__link {
  display: none;
}

.auth .container__about .about__link .button {
  background-color: rgb(9, 68, 157);
  border-color: transparent;
  color: white;
  width: 240px;
  height: 40px;
}

.auth .container__about .about__link .button span {
  margin: 2px 50px 2px 30px;
}

.auth .container__about .about__link .button svg path {
  fill: white;
}

.auth .container__about .about__link .button:hover {
  background-color: rgb(12, 94, 216);
}

.auth .container__close {
  position: absolute;
  margin-left: 10px;
  margin-top: 10px;
}
.auth .container__close svg {
  cursor: pointer;
}

.auth .container__close svg rect {
  transition: cubic-bezier(0.165, 0.84, 0.44, 1) 0.3s;
}

.auth .container__close svg:hover path {
  fill: white;
  box-shadow: 0px 3px 3.96px 0.04px rgba(111, 116, 126, 0.16);
}

.auth .container__close svg:hover rect {
  fill: rgb(12, 94, 216);
}

.auth .container__form {
  display: flex;
  align-items: center;
  background-color: white;
  flex: auto;
  border-radius: 12px;
}

.auth .authentication-form {
  padding: 50px 0;
}

.auth .about__wrapper {
  text-align: center;
  line-height: 1.2;
  color: #f1f1f1;
  padding: 25px 0px;
}

.auth .about__wrapper .logo {
  justify-content: center;
}

.auth .about__wrapper .title {
  margin-top: 20px;
  font-size: 28px;
  font-weight: 500;
}

.auth .about__wrapper .subtitle {
  font-size: 21px;
  font-weight: 300;
}

.auth .about__link {
  position: absolute;
  bottom: 35px;
}

.authentication-form {
  width: 100%;
}

.authentication-form .ant-alert-error {
  margin-bottom: 10px;
}

.authentication-form .title-wrapper__subtitle {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 5px;
}

.authentication-form .ant-form-item {
  margin-bottom: 24px;
}

.authentication-form .ant-form-item-with-help {
  margin-bottom: 5px;
}

.authentication-form .ant-form-item.ant-form-item-with-help .ant-form-explain {
  color: #ff0000;
  font-weight: 600;
  font-size: 11px;
}

.authentication-form
  .ant-form-item.ant-form-item-with-help
  .show-help-leave-active {
  font-weight: 600;
  font-size: 11px;
}

.authentication-form .ant-form-item-required::before {
  content: "";
}

.authentication-form .ant-form-item-required::after {
  content: " ";
}

.authentication-form .ant-form-item-control input {
  height: 38px;
  background-color: rgb(247, 250, 252);
}

.authentication-form .ant-form-item-control input:hover,
.authentication-form .ant-input-affix-wrapper:hover input {
  border: 1px solid rgb(180, 212, 253) !important;
}

.authentication-form .ant-form-item-control input:focus,
.authentication-form .ant-input-password input:focus {
  border: 1px solid rgb(180, 212, 253);
  background-color: rgb(255, 255, 255);
  box-shadow: none;
}

.authentication-form .ant-form-item-required {
  color: #42526e;
  font-size: 12px;
  font-weight: 600;
}

.authentication-form .form-options {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.authentication-form .form-options div {
  margin-top: 10px;
  display: flex;
}

.authentication-form .form-options .button {
  font-weight: 500;
  color: #0052cc;
}

.authentication-form .ant-checkbox .ant-checkbox-inner {
  background-color: #f7fafc;
}

.authentication-form .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #0052cc;
}

.authentication-form .form-options .button:hover {
  color: #3572b0;
}
.authentication-form .form-options .button:hover svg path {
  fill: #3572b0;
}

.authentication-form .checkbox__title {
  font-size: 14px;
  color: #42526e;
}

@media (max-width: 575.98px) {
  .auth .auth__container {
    width: 90%;
  }
  .authentication-form .ant-form-item {
    margin-bottom: 10px;
  }
  .auth .authentication-form {
    padding: 20px 15px 30px;
  }
  .form-header__back-button {
    display: none;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .auth .auth__container {
    width: 85%;
  }
  .auth .authentication-form {
    padding: 25px 0 30px;
  }
  .authentication-form .button--submit {
    margin: 25px 0;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .auth .auth__container {
    width: 80%;
  }
  .auth .authentication-form {
    padding: 30px 0;
  }
  .authentication-form .button--submit {
    margin: 32px 0;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .auth .auth__container {
    flex-direction: column;
  }
  .auth .about__link {
    display: none;
  }
  .auth .authentication-form {
    padding: 50px 0;
  }
}

@media (min-width: 1200px) {
  .auth .container__about {
    border-top-right-radius: 0px;
    border-bottom-left-radius: 12px;
    width: 35%;
  }
  .auth .container__form {
    width: 100%;
    min-height: 460px;
  }
  .auth .container__about .about__wrapper {
    margin: 25px 0px;
  }
  .auth .auth__container {
    flex-direction: row;
  }
  .auth .container__about .about__link {
    display: block;
  }
  .auth .authentication-form {
    padding: 79.5px 0;
  }
}

.copyright {
  font-size: 11px;
  color: #bcc1d3;
  margin-bottom: 20px;
}

.copyright__link {
  color: #f7fafc;
}
