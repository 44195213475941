.logo-upload {
    display: flex;
    --height: 64px;
    --width: 64px;
}

.logo-upload .image {
    height: var(--height);
    width: var(--width);
    margin-right: 8px;
    border-radius: 50%;
    transition: cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.4s;
}

.logo-upload img {
    height: var(--height);
    width: var(--width);
    border-radius: 100%;
}

.logo-upload .title {
    font-size: 17px;
    color: rgb(31, 50, 95);
    font-weight: bold;
    line-height: 1.2;
}

.logo-upload .description {
    font-size: 14px;
    color: rgb(66, 82, 110);
    line-height: 1.5;
    max-width: 320px;
    margin: 20px 0;
    font-weight: 500;
}

.logo-upload .button {
    font-size: 14px;
    line-height: 1.2;
    font-weight: 600;
}

.logo-upload .button span {
    padding: 14px 25px 14px 15px;
}

@media (min-width: 576px) {
    .logo-upload {
        --height: 92px;
        --width: 92px;
    }

    .logo-upload .image {
        margin-right: 16px;
    }
}
