.company-form {
    padding: 0 16px;
}

.company-form__section-header {
    font-size: 14px;
    color: rgb(51, 51, 51);
}

.company-form__field-label label {
    font-size: 12px;
    font-weight: 500;
    color: rgb(66, 82, 110);
}

.company-form .logo-upload {
    margin-top: 16px;
}


.container--dashed {
    border: 1px dashed rgb(203, 214, 226);
    border-radius: 12px;
    background-color: rgb(247, 250, 252);
}

.container__content {
    padding: 28px 50px;
}

.container__footer {
    padding: 15px 20px;
    background-color: white;
    display: flex;
    justify-content: flex-end;
    border-top: 1px dashed rgb(203, 214, 226);
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
}

@media (max-width: 575.98px) {
    .container__content {
        padding: 15px 20px;
    }
}

@media (min-width: 576px) and (max-width: 991.98px) {
    .container__content {
        padding: 20px 30px;
    }
}