.user-form__invite .ant-input {
  font-weight: 500;
}
.user-form__invite .ant-input::placeholder,
.user-form__invite .ant-form-item-label > label {
  color: #42526e;
  font-size: 12px;
  font-weight: 500;
}
.user-form__invite .ant-input:active,
.user-form__invite .ant-input:focus {
  border: 1px solid #b4d4fd;
  box-shadow: 0 3px 4px 0 rgba(111, 116, 126, 0.16);
}

.user-form__invite .ant-form-item {
  margin-bottom: 12px;
}

@media (min-width: 576px) {
  .user-form__invite.ant-form-vertical .ant-form-item-label {
    padding: 0;
    line-height: 32px;
  }
}
