.column-actions {
  width: 140px;
  background-color: #ffffff;
  box-shadow: 0 12px 27.3px 2.7px rgba(25, 39, 52, 0.17);
}

.column-actions__header {
  color: #8993a4;
  font-size: 11px;
  font-weight: 500;
  text-transform: uppercase;
  pointer-events: none;
}

.column-actions__item {
  font-size: 12px;
  line-height: 20px;
  color: #0052cc;
}

.column-actions__item:hover {
  color: #3572b0;
  background-color: #f1f5f7;
}

.column-actions:after {
  content: "";
  position: absolute;
  top: -5px;
  right: 10px;
  width: 10px;
  height: 10px;
  transform: rotateZ(45deg);
  background-color: #fff;
}
