.logo {
  width: 64px;
  height: 64px;
  border-radius: 4px;
}

.logo img {
  width: 100%;
  height: auto;
}

.msg {
  font-size: 20px;
  font-weight: 600;
  margin-top: 4px;
  margin-bottom: 25px;
}

.loading {
  background-color: #f7f7f7;
}

.msg.loading {
  border-radius: 4px;
  height: 30px;
}
