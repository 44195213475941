.card {
  border: 1px solid #dfe1e6;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 3px 4px 0 rgba(111, 116, 126, 0.16);
}
.card__title {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.card__title__icon {
  color: #1f325f;
  margin-right: 20px;
}
.card .ant-card-head {
  padding: 0 22px;
  font-size: 14px;
  line-height: 1;
  font-weight: 600;
  margin-bottom: 0;
}
.card .ant-card-head-title {
  padding: 15px 0;
}
.card .ant-card-head-wrapper {
  min-height: 60px;
}
.card .ant-card-extra a {
  font-size: 14px;
  color: #1f325f;
  font-weight: 600;
  display: flex;
  align-items: center;
}
.card .ant-card-extra a svg {
  font-size: 16px;
  margin-left: 10px;
}
.card .ant-card-body {
  padding: 15px 20px;
  background-color: #f7fafc;
}
.card.no-padding {
  overflow: hidden;
}
.card.no-padding .ant-card-body {
  padding: 0;
  font-size: 0;
}

@media (min-width: 576px) {
  .card__title {
    flex-wrap: nowrap;
  }
  .card .ant-card-head-title {
    padding: 0;
  }
}
