.user-select {
  width: 100%;
  margin-top: 15px;
  margin-bottom: 5px;
}
.user-select .ant-list-item,
.user-select__option .ant-list-item {
  padding: 0;
  margin: 0;
}
.user-select__option .ant-list-item-meta-avatar,
.user-select .ant-select-selection__choice .ant-list-item-meta-avatar {
  margin-right: 10px;
}

.user-select .ant-select-selection {
  border: 1px solid #cbd6e2;
  box-shadow: 0 3px 4px 0 rgba(108, 116, 127, 0.16);
  color: #333;
}
.user-select.ant-select-focused .ant-select-selection {
  border-color: #b4d4fd;
}
.user-select .ant-select-search__field,
.user-select .ant-select-selection__placeholder {
  font-size: 14px;
  color: #42526e;
  font-weight: 500;
}

.user-select .ant-select-search__field {
  margin-left: 7px;
  color: #333;
}

/* Search input tags */
.user-select .ant-select-selection--multiple .ant-select-selection__choice {
  margin-top: 5px;
  margin-right: 12px;
  border-radius: 16px;
  border: 1px solid #dfe1e6;
  background-color: #f7fafc;
  overflow: visible;
  padding: 0 24px 0 0;
}

.user-select.ant-select-lg
  .ant-select-selection--multiple
  .ant-select-selection__rendered
  li.ant-select-selection__choice,
.user-select.ant-select-lg
  .ant-select-selection--multiple
  .ant-select-selection__rendered
  li.ant-list-item {
  line-height: 1;
  height: 28px;
}

.user-select.ant-select-lg
  .ant-select-selection--multiple
  .ant-select-selection__rendered
  li.ant-select-search--inline {
  margin-top: 2px;
}

.user-select .ant-select-selection--multiple {
  padding-bottom: 1px;
}

.user-select .ant-select-selection__choice .ant-list-item-meta-avatar {
  transform: translate(-1px, -1px);
}
.user-select .ant-list-item-meta-description,
.user-select .ant-list-item-meta-title {
  font-size: 11px;
  color: #333333;
  font-weight: 600;
  line-height: 26px;
  margin: 0;
}

.user-select
  .ant-list-item-meta:not(.user-select__no-name)
  .ant-list-item-meta-description {
  display: none;
}

.user-select__no-name .ant-list-item-meta-title {
  display: none;
}

.user-select
  .ant-select-selection--multiple
  .ant-select-selection__choice__remove {
  line-height: 29px;
  right: 6px;
}

.user-select
  .ant-select-selection--multiple
  .ant-select-selection__choice__content {
  overflow: visible;
}

/* Dropdown options */
.user-select__dropdown {
  border: 1px solid #dfe1e6;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 12px 27px 3px rgba(25, 39, 52, 0.17);
}

.ant-select-dropdown .ant-select-dropdown-menu-item {
  padding: 10px 14px;
}

.user-select__option {
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-height: 1;
  background-color: #fff;
  transition: background-color 0.3s linear;
}
.user-select__option:not(:last-child) {
  border-bottom: 1px solid #dfe1e6;
}

.user-select__option .ant-list-item-meta-title {
  font-size: 11px;
  font-weight: 600;
  line-height: 1.5;
  color: #333333;
  margin: 0;
}

.user-select__option .ant-list-item-meta-description {
  font-weight: 500;
  line-height: 1.2;
  font-size: 11px;
  color: #0052cc;
}

.user-select__option:hover,
.user-select__option.ant-select-dropdown-menu-item-selected {
  background-color: #f1f5f7;
  color: #3572b0;
}

.user-select__option:hover .ant-list-item-meta-title,
.user-select__option.ant-select-dropdown-menu-item-selected
  .ant-list-item-meta-title {
  color: #3572b0;
}

.user-select__option.ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled) {
  background-color: #f1f5f7;
  color: #3572b0;
}
.user-select .ant-avatar-string,
.user-select__option .ant-avatar-string {
  font-size: 12px;
  text-transform: uppercase;
}

/* Option Not Found */
.user-select__dropdown.ant-select-dropdown--empty {
  padding: 10px 6px;
}

.select-not-found .ant-list-item {
  flex-wrap: wrap;
}

.select-not-found .ant-list-item-meta-avatar {
  margin-right: 20px;
}

.select-not-found .ant-avatar {
  background-color: #f1f5f7;
  color: #1f325f;
}
.select-not-found .ant-avatar-string {
  top: 6px;
}

.select-not-found .ant-list-item-meta {
  flex-basis: 100%;
}
.select-not-found__content {
  margin-left: 72px;
  margin-top: 10px;
}
.select-not-found + svg {
  display: none;
}
.select-not-found .ant-list-item-meta-title {
  font-size: 16px;
  color: #333333;
  font-weight: 600;
  margin-bottom: 0;
  word-break: break-all;
  white-space: normal;
}

.select-not-found .ant-list-item-meta-description {
  font-size: 13px;
  color: #333333;
}
