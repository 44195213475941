/* Invite Steps */
.invite-steps {
  margin-bottom: 20px;
  position: relative;
}
.invite-step .ant-steps-item-title::after {
  display: none;
}

.invite-step {
  height: 36px;
  display: flex;
  align-items: center;
  padding: 5px 12px 0 6px;
  border-radius: 19px;
  border: 1px solid #dfe1e6;
  background-color: #ffffff;
  box-shadow: 0 3px 4px 0 rgba(111, 116, 126, 0.12);
  flex: 0 1 auto;
  margin-bottom: 10px;
}

.invite-step.ant-steps-item-finish {
  background-color: #0052cc;
}
.invite-step.ant-steps-item-finish .ant-steps-item-title {
  color: #fff !important;
}

.invite-step.ant-steps-item-wait {
  color: #6f747e;
  box-shadow: none;
}
.invite-step.ant-steps-item-wait:hover {
  box-shadow: 0 3px 4px 0 rgba(111, 116, 126, 0.12);
}

.invite-steps.ant-steps-small .ant-steps-item-title {
  font-size: 13px;
  font-weight: 600;
  padding-right: 0;
  color: #333333;
}
.invite-step.ant-steps-item-wait:not(.ant-steps-item-active)
  .ant-steps-item-icon,
.invite-step.ant-steps-item-process:not(.ant-steps-item-active)
  .ant-steps-item-icon {
  background-color: #f1f5f7;
  border-color: #f1f5f7;
}
.invite-step.ant-steps-item-wait:not(.ant-steps-item-active)
  .ant-steps-item-icon
  > .ant-steps-icon,
.invite-step.ant-steps-item-process:not(.ant-steps-item-active)
  .ant-steps-item-icon
  > .ant-steps-icon {
  color: #333;
}

.invite-steps.ant-steps-horizontal.ant-steps-label-horizontal,
.invite-steps.ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item {
  display: block;
}
.invite-steps.ant-steps-small.ant-steps-horizontal:not(.ant-steps-label-vertical)
  .ant-steps-item {
  margin-right: 0;
}

/* Wizard title */
.form-wizard__header {
  margin-bottom: 20px;
}
.form-wizard__header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
}
.form-wizard__header .page-title {
  margin-bottom: 0;
}
.form-wizard__header .page-title + * {
  margin-top: 15px;
}

/* Responsive */
@media (min-width: 576px) {
  .form-wizard__header {
    flex-direction: row;
    align-items: center;
  }
  .form-wizard__header .page-title + * {
    margin-top: 0;
  }

  .invite-step {
    padding-top: 0;
    margin-bottom: 0;
  }

  .invite-steps::before {
    content: "";
    position: absolute;
    top: 18px;
    width: 100%;
    left: 0;
    right: 0;
    height: 1px;
    background-color: #dfe1e6;
  }
  .invite-steps.ant-steps-horizontal.ant-steps-label-horizontal,
  .invite-steps.ant-steps-horizontal.ant-steps-label-horizontal
    .ant-steps-item {
    display: flex;
  }
  .invite-steps.ant-steps-small.ant-steps-horizontal:not(.ant-steps-label-vertical)
    .ant-steps-item {
    margin-right: 12px;
  }
}
