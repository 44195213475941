.radio-buttons {
  box-shadow: 0 3px 4px 0 rgba(111, 116, 126, 0.12);
  margin-bottom: 15px;
  border-radius: 4px;
}
.radio-buttons .ant-radio-button-wrapper {
  font-size: 13px;
  color: #1f325f;
  font-weight: 500;
  height: 34px;
  line-height: 32px;
  border-color: #dfe1e6;
}

@media (min-width: 768px) {
  .radio-buttons {
    margin-bottom: 0;
  }
}
