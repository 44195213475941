/* Table Header */
.table-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 14px 20px;
  border-bottom: 1px solid #dfe1e6;
}
.table-header__title {
  font-size: 14px;
  color: #333333;
  font-weight: 600;
  margin: 0;
}
.table-header .ant-input-search,
.table-header .ant-btn:not(:last-child) {
  margin-right: 15px;
}

.table-header__actions {
  display: flex;
}
