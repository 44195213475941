button.btn.btn--primary.button-upload {
    font-size: 14px;
    line-height: 1.2;
    font-weight: 600;
    border: 1px solid #dfe1e6;
    border-radius: 4px;
    box-shadow: 0 3px 4px 0 rgba(111, 116, 126, .12);
    color: #1f325f;
    width: -moz-fit-content;
    margin: 0;
    padding: 0;
    width: auto;
    height: 46px;
    overflow: visible;
    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;
    -webkit-appearance: none;
    background-color: #fff;
    display: flex;
    align-items: center;
    cursor: pointer;
    -webkit-transition: .2s cubic-bezier(.25, .46, .45, .94);
    transition: .2s cubic-bezier(.25, .46, .45, .94);
}

button.btn.btn--primary.button-upload svg {
    margin: 0 0 0 10px;
}

button.btn.btn--primary.button-upload span {
    padding: 14px 25px 14px 15px;
}