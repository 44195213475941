/* Table user profile */
.table-user-profile .ant-list-item-meta-avatar {
  margin-right: 22px;
}
.table-user-profile .ant-avatar-string {
  font-size: 12px;
  text-transform: uppercase;
}
.table-user-profile .ant-badge-dot {
  width: 10px;
  height: 10px;
  box-shadow: 0 0 0 2px #fff;
}
.table-user-profile .ant-list-item-meta-title {
  font-size: 14px;
  line-height: 1;
  font-weight: 600;
  color: #333333;
  margin-bottom: 7px;
}
.table-user-profile .ant-list-item-meta-description {
  font-size: 11px;
  line-height: 1;
  font-weight: 600;
  color: #0052cc;
}
