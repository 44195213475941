.users-page .ant-tabs {
  margin-top: -16px; /* tab has padding top 16px */
}
.users-page .ant-tabs-nav .ant-tabs-tab {
  font-size: 14px;
  font-weight: 600;
  color: #1f325f;
  padding: 16px 0;
  text-align: center;
}

.users-page .ant-tabs-nav .ant-tabs-tab:hover {
  color: #3572b0;
}

.users-page .ant-tabs-nav .ant-tabs-tab-active {
  color: #0052cc;
}

.users-page__header {
  margin-bottom: 20px;
}
.users-page__buttons {
  margin-top: 25px;
}
.users-page__buttons .ant-btn:not(:last-child) {
  margin-bottom: 15px;
}
.users-page__header,
.users-page__buttons {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
}
.users-page__header .page-title {
  margin-bottom: 0;
}
.users-page__header .page-title + * {
  margin-top: 15px;
}

/* Content Wrapper */
.users-page__wrapper {
  border: 1px dashed #cbd6e2;
  border-radius: 12px;
  background-color: #f7fafc;
  padding: 20px 30px;
}

/* Divider */
.divider.ant-divider-horizontal {
  margin: 20px 0;
}

/* Alert */
.users-alert.alert {
  margin: -8px 0;
}

/* Table team profile */
.table-team-profile {
  font-size: 14px;
  line-height: 16px;
  color: #333333;
  font-weight: 600;
  display: flex;
  align-items: center;
}
.table-team-profile svg {
  color: #1f325f;
  margin-right: 10px;
}

/* Responsive */
@media (min-width: 576px) {
  .users-page__wrapper {
    padding: 30px 40px;
  }
  .users-page__header,
  .users-page__buttons {
    flex-direction: row;
    align-items: center;
  }
  .users-page__buttons .ant-btn:not(:last-child) {
    margin-bottom: 0;
  }
  .users-page__header .page-title + * {
    margin-top: 0;
  }
}

@media (min-width: 1200px) {
  .users-page {
    max-width: 85vw;
    margin: 0 auto;
  }
}
