.sort-button {
  position: relative;
  color: #1f325f;
  font-size: 13px;
  font-weight: 500;
  line-height: 32px;
  border: 1px solid #dfe1e6;
  box-shadow: 0 3px 4px 0 rgba(111, 116, 126, 0.16);
  border-radius: 4px;
  transition: all 0.3s linear;
  overflow: hidden;
}
.sort-button:not(:last-child) {
  margin-right: 15px;
}
.sort-button *::selection {
  background-color: transparent;
  color: inherit;
}
.sort-button:hover {
  color: #42526e;
  border: 1px solid #dfe1e6;
  box-shadow: 0 12px 27px 1px rgba(111, 116, 126, 0.22);
}

.sort-button__icon {
  display: flex;
  align-items: center;
  height: 100%;
  border-right: 1px solid #dfe1e6;
  z-index: 2;
}
.sort-button__icon svg {
  transform: unset !important;
}

.sort-button__icon,
.sort-button__title.ant-dropdown-trigger {
  position: relative;
  padding: 8px 10px;
  cursor: pointer;
  background-color: #fff;
  transition: background-color 0.3s linear;
}
.sort-button__icon:hover,
.sort-button__title:hover {
  background-color: #f1f5f7;
}

.sort-button__title.ant-dropdown-open {
  padding-left: 49px;
  margin-left: -39px;
}

/* Sort dropdown */
.sort-button__dropdown {
  padding: 0;
  background-color: #f7fafc;
  border: 1px solid #dfe1e6;
  box-shadow: 0 12px 27px 3px rgba(25, 39, 52, 0.17);
  overflow: hidden;
  width: 160px;
}
.sort-button__dropdown__header {
  pointer-events: none;
}
.sort-button__dropdown__item {
  padding: 7px 14px;
  font-size: 12px;
  line-height: 22px;
  font-weight: 500;
  color: #1f325f;
}
.sort-button__dropdown__item:first-child {
  color: #42526e;
  font-size: 11px;
  font-weight: 400;
  text-transform: uppercase;
}
.sort-button__dropdown__item:not(:last-child) {
  border-bottom: 1px solid #dfe1e6;
}
.sort-button__dropdown__item.ant-dropdown-menu-item-selected:hover,
.sort-button__dropdown__item:not(:first-child):hover {
  background-color: #b4d4fd;
  color: #3572b0;
}
.sort-button__dropdown__item.ant-dropdown-menu-item-selected,
.sort-button__dropdown__item--active {
  color: #0052cc;
  background-color: #fff;
}
